// importing fonts
@import url('https://fonts.googleapis.com/css2?family=Benne&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

::-webkit-scrollbar{
  width: .6em;
}
::-webkit-scrollbar-track{
  background-color: white;
  margin-block: .3em;
}
::-webkit-scrollbar-thumb{
  background-color: rgb(192, 164, 119);
  border: 2px solid white;
  border-radius: 100vw;
}
::-webkit-scrollbar-thumb:hover{
  background-color: rgb(54, 54, 54);
}

html {
  scroll-behavior: smooth;
}