.navbar {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12);
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  padding-left: 3%;
  padding-right: 3%;
  color: #fff;
  transition: background-color 400ms, color 400ms, height 400ms;

  &.active {
    background-color: #fff;
    color: #444242;
    height: 80px;

    .inner > * {
      transform: scale(1);
    }

    .navLogo svg {
      color: rgb(32, 31, 29);
    }
  }
}
.inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1080px;
  width: 100%;
  position: relative;

  & > * {
    transform: scale(1.07);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.3);
  }
}

.logo {
  transition: transform 400ms;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: transform 400ms;

  .active {
    color: rgb(192, 164, 119);
  }
  a {
    color: inherit;
    display: flex;
    align-items: center;
    padding: 1rem;
    height: 100%;
    font-weight: 600;
    font-size: 18px;
  }
}
.navigationButton {
  display: none;
}
.number {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  height: 50px;
  justify-content: space-between;
  transition: transform 400ms;

  h3 {
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  span {
    display: flex;
    align-items: center;
  }

  svg {
    color: rgb(188, 166, 130);
    margin-right: 10px;
  }
}
// responsive menu
.navLogo {
  height: 100%;
  width: 70px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    font-size: 50px;
    color: #fff;
  }
}
.responsiveMenu {
  visibility: hidden;
  transform: translateX(-250px) !important;
  transition: visibility 0.5s, transform 0.5s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  background-color: white;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 12%);
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9999;
  
  &.responsiveMenuActive {
    visibility: visible;
    transform: translateX(0) !important;
  }

  a {
    padding: 1rem 1rem;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    color: #444242;
    font-weight: 600;
    user-select: none;
  }

  .navLogoMenu {
    width: 70px;
    height: 70px;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      font-size: 40px;
      color: #444242;
    }
  }

  .logoHolder {
    width: 100%;
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    margin-bottom: 1.2rem;
  }
}

.numberLanguageWrapper {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;

  .languageButton{
    margin-right: 1.5rem;
    background-color: rgb(0, 0, 0, 0);
    border: 2px solid rgb(192, 164, 119);
    border-radius: 50%;
    color: white;
    width: 45px;
    height: 45px;
    cursor: pointer;
    user-select: none;
    
  }
  .languageButton.active{
    color: #444242;
    font-weight: 600;
  }
}
.languageButtonResponsive{
  background-color: rgb(0, 0, 0, 0);
  border: 2px solid rgb(192, 164, 119);
  border-radius: 50%;
  color: white;
  width: 45px;
  height: 45px;
  cursor: pointer;
  user-select: none;
  display: none;
}
.languageButtonResponsive.active{
  color: #444242;
  font-weight: 600;
}

// responsive style
@media (max-width: 1081px) {
  .navigation {
    display: none;
  }
  .navLogo {
    display: flex;
  }
}
@media(max-width: 700px){
  .numberLanguageWrapper{
    text-align: center;
    .languageButton{
      display: none;
    }
  }
  .languageButtonResponsive{
    display: block;
  }
}


