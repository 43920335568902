.contact{
  width: 100%;
  min-height: 500px;
  display: flex;
  justify-content: center;
  z-index: 30;
  padding-top: 5rem;
  padding-bottom: 5rem;
  

  .inner{
    max-width: 1080px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 3%;
  }
}
.personal{
  display: flex;
  flex-direction: column;
  width: 46%;
  justify-content: center;

  h1{
    color: #444242;
    font-size: 2rem;
  }

  .mail{
    margin-top: 8px;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;

    svg{
      color: rgb(188,166,130);
      font-size: 1.4rem;
      margin-bottom: -4px;
      margin-right: 4px;
    }
  }

  .number{
    display: flex;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;
    margin-top: 4px;
    font-size: 1.3rem;

    svg{
      color: rgb(188,166,130);
      margin-top: 5px;
      margin-right:6px;
      font-size: 1.15rem;
    }
  }

  .personalOr{
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    span{
      position: absolute;
      width: 100%;
      border: 1px solid rgb(230, 230, 230);
    }

    p{
      background-color: white;
      color: #444242;
      font-weight: 600;
      z-index: 2;
      padding: 5px;
    }
  }
}
.or{
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span{
    position: absolute;
    height: 100%;
    border: 1px solid rgb(230, 230, 230);
  }

  p{
    background-color: white;
    color: #444242;
    font-weight: 600;
    z-index: 2;
    padding: 5px;
  }
}

.emailContainer{
  display: flex;
  flex-direction: column;
  width: 46%;

  .question{
    color: #444242;
    font-size: 1.4rem;
    margin-bottom: .5rem;
  }

  .nickname{
    width: 100%;
    margin-top: 1rem;
    position: relative; 
    font-family: "Roboto", sans-serif;

    input {
      width: 100%;
      padding: .8rem 1rem;
      padding-top: 1rem;
      font-weight: lighter;
      background-color: white;
      border: none;
      border-radius: 0.575rem;
      transition: 0.3s;
      outline: none;
      font-size: 1.1rem;
      border: 1px solid #444242;

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
      &:focus{
        box-shadow: #444242 0px 1px 1px, #444242 0px 0px 0px 1px;
      }

      &:focus + label,
      &:not(:placeholder-shown) + label {
        transform: scale(0.75) translateY(-3.1rem);
        font-weight: 600;
      }
    }

    label {
      position: absolute;
      left: 1rem;
      margin-top: .8rem;
      font-size: 1.2rem;
      transition: all 0.15s;
      transform: translateY(-0.75rem);
      top: 0.3125rem;
      z-index: 10;
      transform-origin: 0;
      pointer-events: none;
      background-color: white;
      padding: .3rem;
      line-height: 1.85rem;
    }

    .labelFilled {
      transform: scale(0.75) translateY(-2rem);
      color: rgb(188,166,130);
    }
  }

  .email{
    width: 100%;
    margin-top: 1rem;
    position: relative; 
    font-family: "Roboto", sans-serif;

    input {
      width: 100%;
      padding: .8rem 1rem;
      padding-top: 1rem;
      font-weight: lighter;
      background-color: white;
      border: none;
      border-radius: 0.575rem;
      transition: 0.3s;
      outline: none;
      font-size: 1.1rem;
      border: 1px solid #444242;

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
      &:focus{
        box-shadow: #444242 0px 1px 1px, #444242 0px 0px 0px 1px;
      }

      &:focus + label,
      &:not(:placeholder-shown) + label {
        transform: scale(0.75) translateY(-3.1rem);
        font-weight: 600;
      }
    }

    label {
      position: absolute;
      left: 1rem;
      margin-top: .8rem;
      font-size: 1.2rem;
      transition: all 0.15s;
      transform: translateY(-0.75rem);
      top: 0.3125rem;
      z-index: 10;
      transform-origin: 0;
      pointer-events: none;
      background-color: white;
      padding: .3rem;
      line-height: 1.85rem;
      
    }

    .labelFilled {
      transform: scale(0.75) translateY(-2rem);
      color: rgb(188,166,130);
    }
  }

  .textArea{
    width: 100%;
    margin-top: 1rem;
    position: relative; 
    font-family: "Roboto", sans-serif;

    textarea {
      height: 8rem;
      resize: none;
      width: 100%;
      padding: .8rem 1rem;
      padding-top: 1rem;
      font-weight: lighter;
      background-color: white;
      border: none;
      border-radius: 0.575rem;
      transition: 0.3s;
      outline: none;
      font-size: 1.1rem;
      border: 1px solid #444242;

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
      &:focus{
        box-shadow: #444242 0px 1px 1px, #444242 0px 0px 0px 1px;
      }

      &:focus + label,
      &:not(:placeholder-shown) + label {
        transform: scale(0.75) translateY(-3.1rem);
        font-weight: 600;
      }
    }

    label {
      position: absolute;
      left: 1rem;
      margin-top: .8rem;
      font-size: 1.2rem;
      transition: all 0.15s;
      transform: translateY(-0.75rem);
      top: 0.3125rem;
      z-index: 10;
      transform-origin: 0;
      pointer-events: none;
      background-color: white;
      padding: .3rem;
      line-height: 1.85rem;
    }

    .labelFilled {
      transform: scale(0.75) translateY(-2rem);
      color: rgb(188,166,130);
    }
    span{
      position: absolute;
      width: calc(100% - 2px);
      margin-left: 1px;
      height: 17px;
      background-color: white;
      margin-top: 1px;
      border-top-right-radius: 0.575rem;
      border-top-left-radius: 0.575rem;
      left: 0;
      z-index: 5;
    }
  }

  .btn{
    cursor: pointer;
    border: none;
    position: relative;
    margin-top: 1rem;
    color: white;
    font-size: 1rem;
    width: 250px;
    height: 55px;
    border-radius: .125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(192, 164, 119);
    transition: all .5s;

    svg{
      position: absolute;
      margin-left: 200px;
      margin-top: 3px;
      transition: all .5s;
      color: rgb(192, 164, 119);
      z-index: -1;
    }
      
    &:hover{
      background-color: rgb(207, 178, 130);
      width: 280px;
        
      svg{
        display: flex;
        margin-left: 225px;
        transition: all .5s;
        color: white;
        z-index: 1;
      }
    }
  }
}
.messageContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  
  h1{
    text-align: center;
    font-size: 1.5rem;
    color: #444242;
    line-height: 1.95rem;
  }
}
// animation
.animation{
  opacity: 0;
  transition: 500ms;
  transform: translateY(50px);
  filter:blur(5px);
}
.animation.show{
  transform: translateY(0);
  opacity: 1;
  filter:blur(0)
}

// responsive style
@media(max-width: 940px){
  .contact{
    .inner{
      flex-direction: column;
    }
  }
  .personal{
    width: 100%;
    align-items: center;
    
    h1{
      text-align: center;
    }

    .personalOr{
      max-width: 400px;
    }
  }
  .or{
    height: 60px;
    width: 100%;

    span{
      width: 100%;
      height: 0;
    }
  }
  .emailContainer{
    width: 100%;
  }
}

@media(max-width:440px){
  .personal{
    h1{
      font-size: 1.5rem;
    }
  }
}