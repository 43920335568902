.socials {
  display: flex;
  align-items: center;
  font-size: 2.5rem;
  margin-bottom: 50px;

  &Link {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 100vw;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 70px;
    height: 70px;
    overflow: hidden;
    color: #fff;
    transition: width 400ms;

    &Wrapper {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 10px;
      width: max-content;
    }

    & svg {
      fill: currentColor;
    }

    &Text {
      font-size: 22px;
      font-weight: bold;
      margin-left: 17px;

      &Span {
        margin-right: 7px;
      }
    }

    &RedirectIcon {
      font-size: 20px;
      margin-bottom: -2px;
      margin-left: 10px;
      display: block;
    }

    &Facebook {
      background-color: #316ff6;

      &:hover,
      &:focus-visible {
        width: 215px;
      }

      & .socialsLinkIcon {
        margin-left: 4px;
      }
    }

    &Whatsapp {
      background-color: #4fce5d;
      font-size: 3.2rem;
      &:hover,
      &:focus-visible {
        width: 230px;
      }
    }

    &Telegram {
      background-color: #0088cc;

      &:hover,
      &:focus-visible {
        width: 215px;
      }

      & .socialsLinkIcon {
        margin-left: 3px;
      }
    }
  }
}
// responsive design
@media (max-width: 500px) {
  .socialsLink:hover,
  .socialsLinkWhatsapp:hover,
  .socialsLinkTelegram:hover {
    width: 70px;
    height: 70px;
  }
}
