.consult {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 750px;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  &::after {
    background-color: rgb(0, 0, 0, 1);
    position: absolute;
  }

  .inner {
    margin-top: 80px;
    max-width: 1080px;
    width: 100%;
    z-index: 5;
    margin-left: 3%;

    h1 {
      color: white;
      font-size: 2.8rem;
      max-width: 600px;
      line-height: 59px;
    }
    p {
      margin-top: 1rem;
      max-width: 450px;
      font-size: 1.3rem;
      color: white;
      line-height: 1.8rem;
    }
    .contactLink {
      position: relative;
      margin-top: 8rem;
      color: white;
      font-size: 1rem;
      width: 250px;
      height: 55px;
      border-radius: 0.125rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(192, 164, 119);
      transition: all 0.5s;

      svg {
        position: absolute;
        margin-left: 200px;
        margin-top: 3px;
        transition: all 0.5s;
        color: rgb(192, 164, 119);
        z-index: -1;
      }

      &:hover {
        background-color: rgb(207, 178, 130);
        width: 280px;

        svg {
          display: flex;
          margin-left: 225px;
          transition: all 0.5s;
          color: white;
          z-index: 1;
        }
      }
    }
    .navigationer {
      width: 100%;
      height: 80px;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1));
  }
}

.logo {
  position: absolute;
  top: 130px;
  z-index: 2;
  opacity: 0.8;  
}

// animation
.titleAnimation {
  opacity: 0;
  transition: 500ms;
  transform: translateY(50px);
  filter: blur(5px);
}
.titleAnimation.titleShow {
  transform: translateY(0);
  opacity: 1;
  filter: blur(0);
}
.paragraphAnimation {
  opacity: 0;
  transition: 500ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateY(50px);
  filter: blur(5px);
}
.paragraphAnimation.paragraphShow {
  transform: translateY(0);
  opacity: 1;
  filter: blur(0);
}
.contactLink {
  opacity: 0;
  transition: 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: translateX(50px);
  filter: blur(5px);
}
.contactLink.btnShow {
  transform: translateX(0);
  opacity: 1;
  filter: blur(0);
}

// responsive style
@media (max-width: 621px) {
  .consult {
    .inner {
      h1 {
        font-size: 2.3rem;
        line-height: 2.9rem;
      }
      .navigationer {
        margin-top: 30px;
      }
    }
  }
}
@media (max-width: 376px) {
  .consult {
    .inner {
      h1 {
        font-size: 2rem;
        line-height: 2.6rem;
      }
    }
  }
}
