.logo{
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 177.55px;
  height: 52px;
  user-select: none;
  border-top: 2px solid rgb(188,166,130);
  border-bottom: 2px solid rgb(199, 175, 136);
  border-left: 2px solid rgb(211, 183, 137);
  border-right: 2px solid rgb(192, 164, 119);

  h1{
    color: rgb(188,166,130);
    margin-top: 8px;
    font-size: 1.8rem;
    font-family: "Benne", serif;
    font-weight: 400;
    font-style: normal;
  } 
  
}
.miniLogo{
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 55.55px;
  height: 52px;
  user-select: none;
  display: none;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  border-top: 2px solid rgb(188,166,130);
  border-bottom: 2px solid rgb(199, 175, 136);
  border-left: 2px solid rgb(211, 183, 137);
  border-right: 2px solid rgb(192, 164, 119);

  h1{
    color: rgb(188,166,130);
    margin-top: 8px;
    font-size: 1.8rem;
    font-family: "Benne", serif;
    font-weight: 400;
    font-style: normal;
  } 
}
.footerLogo{
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 177.55px;
  height: 52px;
  border: 2px solid white;

  h1{
    margin-top: 8px;
    color: white;
    font-size: 1.8rem;
    font-family: "Benne", serif;
    font-weight: 400;
    font-style: normal;
  } 
}
.footerMiniLogo{
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  display: none;
  width: 55.55px;
  height: 52px;
  border: 2px solid white;

  h1{
    margin-top: 8px;
    color: white;
    font-size: 1.8rem;
    font-family: "Benne", serif;
    font-weight: 400;
    font-style: normal;
  } 
}

// responsive
@media(max-width: 1081px){
  .logo{
    display: none;
  }
  .miniLogo{
    display: flex;
  }
}
@media(max-width: 700px){
  .miniLogo{
    display: none;
  }
}
@media(max-width: 651px){
  .footerLogo{
    display: none;
  }
  .footerMiniLogo{
    display: flex;
  }
}