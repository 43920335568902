.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 30;
  background-color: rgb(192, 164, 119);

  .inner {
    max-width: 1080px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 3%;
    padding-right: 3%;
    position: relative;
  }
}
.up {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 1rem;

  svg {
    color: white;
    font-size: 30px;
  }
  &:hover {
    background-color: rgb(194, 158, 101);
  }
}
.navigationHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .nav {
    display: flex;

    a {
      color: white;
      font-weight: 600;
      font-size: 1.2rem;
      margin-left: 1rem;
    }
  }
}
.infoHolder {
  margin-top: 1rem;

  .number {
    font-size: 1.5rem;
    color: white;
    margin-top: 0.5rem;

    svg {
      font-size: 1.3rem;
      margin-bottom: -2px;
    }
  }
  .mail {
    font-size: 1.5rem;
    color: white;
    margin-top: 0.5rem;

    svg {
      font-size: 1.6rem;
      margin-bottom: -5px;
    }
  }
}
.contactHolder {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 4%;
  margin-top: 130px;

  svg {
    color: white;
    margin-left: 0.5rem;
    font-size: 1.8rem;

    &:hover {
      color: #444242;
    }
  }
}

.copyright {
  width: 100%;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  color: white;
  margin: 0 auto;
  margin-top: 2rem;
  line-height: 1.5rem;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  padding-left: 2rem;
  padding-right: 2rem;

  a {
    color: rgb(46, 41, 41);
    position: relative;
  }
  a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0; /* Start with no width */
    height: 2px;
    background-color: rgb(46, 41, 41);
    transition: width 0.7s ease; /* Transition width property */
  }

  a:hover::after {
    width: 100%; /* Grow to full width on hover */
  }

  .gio{
    span{
      position: absolute;
      display: none;
    }
    &:hover{
      span{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 320px;
        height: 180px;
        margin-top: -220px;
        border-radius: .2rem;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        right: 0;
      }
    }
  }
  .luka{
    span{
      position: absolute;
      display: none;
    }
    &:hover{
      span{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 320px;
        height: 180px;
        margin-top: -220px;
        border-radius: .2rem;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        right: 0;
      }
    }
  }
}

// responsive style
@media (max-width: 540px) {
  .infoHolder {
    padding-bottom: 1rem;
  }
  .contactHolder {
    margin-top: 170px;
  }
  .navigationHolder {
    .nav {
      a {
        font-size: 1rem;
      }
    }
  }
}
@media (max-width: 400px) {
  .footer {
    min-height: 350px;
  }
}
@media (max-width: 360px) {
  .infoHolder {
    .number {
      font-size: 1.3rem;
      margin-top: 0.5rem;

      svg {
        font-size: 1.3rem;
        margin-bottom: -2px;
      }
    }
    .mail {
      font-size: 1.3rem;
      margin-top: 0.5rem;

      svg {
        font-size: 1.6rem;
        margin-bottom: -5px;
      }
    }
  }
}
