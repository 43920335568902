.info{
  width: 100%;
  min-height: 500px;
  background-color: #242424;
  display: flex;
  justify-content: center;
  z-index: 30;
  color: white;
  padding-bottom: 5rem;
  position: relative;

  .inner{
    max-width: 1080px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navigator{
      position: absolute;
      width: 100%;
      height: 80px;
      left: 0;
      bottom: 0;
    }
  }
}
.personal{
  display: flex;
  flex-direction: column;
  width: 40%;
  padding-top: 4.5rem;
  align-items: center;

  .image{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .name{
    font-size: 1.5rem;
    margin-top: 5px;
    line-height: 1.8rem;
  }
  .mail{
    margin-top: 8px;
    font-family: "Roboto", sans-serif;
    line-height: 1.3rem;

    svg{
      color: rgb(188,166,130);
      font-size: 1.2rem;
      margin-bottom: -4px;
      margin-right: 4px;
    }
  }
  .number{
    display: flex;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;
    margin-top: 4px;
    font-size: 1.1rem;
    line-height: 1.3rem;

    svg{
      color: rgb(188,166,130);
      margin-top: 5px;
      margin-right:6px;
      font-size: .9rem;
    }
  }
}
.personalInfo{
  padding-top: 4.5rem;
  width: 68%;

  h1{
    line-height: 2.3rem;
  }

  ul{
    margin-top: 1rem;
    margin-left: 1rem;

    li{
      font-size: 1.5rem;
      margin-top: 1.5rem;
      list-style: disc;
      color: white;
      line-height: 1.95rem;
    }
  } 
}
// personal info animations
.titleAnimation{
  opacity: 0;
  transition: 500ms;
  transform: translateY(50px);
  filter:blur(5px);
}
.titleAnimation.titleShow{
  transform: translateY(0);
  opacity: 1;
  filter:blur(0)
}
.animation{
  position: relative;
  opacity: 0;
  visibility: hidden;
  transition: 500ms;
  transform: translateY(-50px);
  filter:blur(5px);
}
.animation.animationShow{
  visibility: visible;
  display: flex;
  transform: translateY(0);
  opacity: 1;
  filter:blur(0)
}

// responsive style
@media(max-width: 700px){
  .inner{
    flex-direction: column;
  }
  .personal{
    width: 100%;
  }
  .personalInfo{
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    ul{
      text-align: start;
      margin-left: 10%;
      margin-right: 5%;

      li{
        font-size: 1.3rem;
      }
    }
  }
}
@media(max-width: 550px){
  .personalInfo{
    ul{
      margin-left: 10%;
      margin-right: 5%;
    }
  }
}
