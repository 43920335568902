.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .navigator {
    height: 80px;
  }
}


